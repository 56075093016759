.HelpIcon {
    align-self: center;
    width: 16px;
    height: 16px;
    color: #4BA7C3;
    margin-left: 10px;
}

.IsDriveThroughLabel {
    color: #242424;
    margin: '10px 0 20px 0';
    font-family: Helvetica, sans-serif;
    font-size: '16px';
    font-style: 'normal';
    font-weight: 400;
    line-height: 'normal';
}

.LabelWithPopover {
    display: flex;
    align-items: center;
}

.CancellationInfoBox {
    border: 1px solid red;
    padding: 11px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    line-height: 18.4px;
    gap: 4px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
    width: 97%;
}

.ExtendTrialInfoBox {
    background-color: #FFC4001A;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    line-height: 18.4px;
    gap: 4px;
    font-family: 'Helvetica', sans-serif;
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
    width: 97%;
}

.IconExclamationTriangle {
    align-self: center;
    width: 18px;
    height: 18px;
}

.SectionDivider {
    border-top: 1px solid #e5e5e5;
    margin: 40px 0;
    width: 100%;
}

.ErrorMessage {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}


.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 20px 0;
}

.sectionHeaderContent {
    display: flex;
    align-items: center;
}

.sectionHeaderCustom {
    margin: 50px 0 10px 0;
}

.sectionTitle {
    font-weight: 800;
    color: #242424;
    margin: 0;
}

.iconMargin {
    margin-left: 8px;
}

.clickableRow {
    border: 3px solid transparent;
    cursor: pointer;
}

.selectedPlan {
    border-color: #a94442;
}

.borderSoft {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.addPlanButton {
    color: #53A0C6;
    padding: 3px 7px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
}

.addPlanButton:hover {
    text-decoration: underline;
}

.currentPlanContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activeLabel {
    background-color: #3e8f3e; 
    color: white; 
    padding: 5px 10px; 
    border-radius: 5px; 
    font-weight: bold; 
    line-height: normal;
    margin-left: auto;
}
