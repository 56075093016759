.Button {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
    color: #53A0C6;
    padding: 0;
    margin-right: 10px;
}

/*.Button:focus {*/
/*    color: #53A0C6;*/
/*    text-decoration: none;*/
/*}*/

.ButtonWrapper {
    text-align: right;
}

.TwoButtonWrapper {
    text-align: right;
}

.RowWrapper {
    display: flex;
    align-items: center;
}

.ColWrapper {
    display: flex;
    align-items: center;
}

.TitleWrapper {
    width: 100%;
}

.ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


