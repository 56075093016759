.planCard {
    background-color: #F8F9FA;
    padding: 20px;
    border-radius: 8px;
    margin-top: 10px;
    position: relative;
}

.deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}


.label {
    font-weight: 500;
    color: #242424;
}

.value {
    font-weight: 400;
    color: #6c757d;
}

.editButton {
    color: #53A0C6;
    font-weight: 400;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    display: block;
    margin-left: auto;
}

.editButton:hover {
    text-decoration: underline;
}


