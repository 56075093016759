.TalentRowItemTop {
    border-top: none;
    border-left: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: bold;
}

.TalentRowItemTop:first-child {
    border-top: 1px solid #E6E6E6;
}

.TalentRowItemTopSelected {
    background-color: #E6E6E6;
}

.TalentInfoWrapper {
    padding: 10px;
    flex: 1;
}

.TalentInfoRow {
    text-align: center;
    margin-bottom: 5px;
}

.TalentMessageRow {
    text-align: center;
    margin-bottom: 5px;
}

.TalentStatusRow {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statusItem {
    margin-right: 10px;
}

.DropDownIndicatorWrapper {
    width: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E6E6E6;
}

.DropDownIndicator {
}

.RightColumnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AcceptedIcon {
    margin-left: 3px;
    color: #28b521;
}

.SkippedIcon {
    margin-left: 3px;
    color: #545454;
}

.RejectedIcon {
    margin-left: 3px;
    color: red;
}

.InterviewIcon {
    margin-left: 3px;
    color: darkgoldenrod;
}

.ScheduledAtIcon {
    margin-left: 3px;
    color: deepskyblue;
}

.RefusedIcon {
    margin-left: 10px;
    color: blueviolet;
}

.WaitingIcon {
    margin-left: 3px;
    color: #979797;
}

.ChevronIcon {
    transition-property: transform;
    transition-duration: .5s;
}

.ChevronIconActive {
    transform: rotate(-180deg);
}

.Link {
    margin-right: 10px;
    color: #333;
}

.NameRowIcons {
    font-size: 10px;
    margin-left: 5px;
}

.ApolloSyncFailedIcon {
    font-size: 15px;
    color: #FF0000;
    vertical-align: middle;
}

.ApolloIcon {
    margin-left: .5rem;
    height: 1rem;
}

.RecommendedTalentBadge {
    margin-left: 5px;
    color: #1A234A;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #EEEEEE;
    border-radius: 50%;
    padding: 6px;
}

.TurnkeyBadge {
   margin-left: 10px;
}

/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .TalentInfoWrapper {
        padding: 10px;
        flex: 1;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
    }


    .TalentInfoRow {
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 450px;
    }

    .TalentMessageRow {
        flex: 1;
        text-align: left;
        margin-bottom: 0;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}